// src/components/AdErrorBoundary.tsx
import React from 'react';

interface AdErrorBoundaryProps {
    children?: React.ReactNode;
}

interface AdErrorBoundaryState {
    hasError: boolean;
}

class AdErrorBoundary extends React.Component<AdErrorBoundaryProps, AdErrorBoundaryState> {
    constructor(props: {}) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render shows the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        // You can log the error to an error reporting service if needed
        console.error('AdErrorBoundary caught an error:', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // Suppress the error by rendering null or a fallback UI
            return null;
        }

        return this.props.children;
    }
}

export default AdErrorBoundary;