import { Box } from '@mui/material';
import React, { useEffect } from 'react';

// Define the shape of the component's props
interface AdsenseComponentProps {
    adClient: string;
    adSlot: string;
}

// Extend the Window interface to include adsbygoogle
declare global {
    interface Window {
        adsbygoogle: any[];
    }
}

const AdsenseComponent: React.FC<AdsenseComponentProps> = ({ adClient, adSlot }) => {
    useEffect(() => {
        // Check if window is defined (to ensure we're on the client side)
        if (typeof window !== 'undefined') {
            try {
                // Initialize adsbygoogle array if it doesn't exist
                if (!Array.isArray((window as any).adsbygoogle)) {
                    (window as any).adsbygoogle = [];
                }

                // Push a new ad request
                (window as any).adsbygoogle.push({});
            } catch (error) {
                // Catch and suppress any errors during ad initialization
                console.error('AdComponent error:', error);
            }
        }
    }, []);

    return (
        <Box sx={{ textAlign: 'center' }}>
            <ins
                className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client={adClient}
                data-ad-slot={adSlot}
                data-ad-format="fluid"
                data-ad-layout="in-article"
            ></ins>
        </Box>
    );
};

export default AdsenseComponent;