// src/components/CustomLink.tsx
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface CustomLinkProps {
    to: string;
    text: string;
    external?: boolean;
    icon?: React.ReactNode;
}

const CustomLink: React.FC<CustomLinkProps> = ({ to, text, external = false, icon }) => {
    return (
        <Link
            component={external ? 'a' : RouterLink}
            to={external ? undefined : to}
            href={external ? to : undefined}
            target={external ? '_blank' : '_self'}
            rel={external ? 'noopener noreferrer' : undefined}
            underline="none"
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
                color: '#FFFFFF',
                '&:hover': {
                    color: '#F3E5F5',
                },
            }}
        >
            {icon && (
                <span style={{ display: 'flex', alignItems: 'center', marginRight: 4 }}>
                    {icon}
                </span>
            )}
            <Typography variant="body1">{text}</Typography>
            {external && (
                <OpenInNewIcon sx={{ fontSize: '1rem', marginLeft: '4px' }} />
            )}
        </Link>
    );
};

export default CustomLink;