
// Update in src/components/QRCodeGenerator.tsx
import React, { useState, useRef } from 'react';
import {
    TextField, Button, Typography, Container, Box, Snackbar,
    Alert,
} from '@mui/material';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import { QRCodeCanvas } from 'qrcode.react';
import { Helmet } from 'react-helmet';
import logo from '../assets/qrixy-logo.png';
import MenuBar from './MenuBar';
import { isMobile } from 'react-device-detect';
import CustomLink from './base/CustomLink';

const QRCodeGenerator: React.FC = () => {
    const [url, setUrl] = useState<string>('');
    const qrRef = useRef<HTMLDivElement>(null);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

    const isValidUrl = (urlString: string): boolean => {
        try {
            new URL(urlString);
            return true;
        } catch {
            return false;
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUrl(e.target.value);
    };

    const handleDownload = () => {
        if (qrRef.current) {
            const canvas = qrRef.current.querySelector('canvas');
            if (canvas) {
                const pngUrl = canvas
                    .toDataURL('image/png');
                const downloadLink = document.createElement('a');
                downloadLink.href = pngUrl;
                downloadLink.download = 'qr-by-qrixy.png';
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                setSnackbarMessage('QR Code downloaded.');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            }
        }
    };

    const handleShare = async () => {
        if (qrRef.current) {
            const canvas = qrRef.current.querySelector('canvas');
            if (canvas) {
                canvas.toBlob(async (blob) => {
                    if (blob) {
                        const filesArray = [new File([blob], 'qr-by-qrixy.png', { type: 'image/png' })];
                        const shareData = {
                            files: filesArray,
                            title: 'QR code by QRrixy',
                            text: 'Hey. I just created this QR code using Qrixy. Check it out!',
                        };

                        try {
                            if (navigator.canShare && navigator.canShare({ files: filesArray })) {
                                await navigator.share(shareData);
                                setSnackbarMessage('QR Code shared successfully.');
                                setSnackbarSeverity('success');
                                setSnackbarOpen(true);
                            } else {
                                setSnackbarMessage('Sharing is not supported on this device.');
                                setSnackbarSeverity('error');
                                setSnackbarOpen(true);
                            }
                        } catch (error) {
                            ;
                        }
                    }
                }, 'image/png');
            }
        }
    };

    return (
        <>
            <Helmet>
                <title>Qrixy - Convert URLs to QR Codes instantly</title>
                <link rel="canonical" href="https://www.qrixy.me" />
                <meta
                    name="description"
                    content="Qrixy is a simple React application that converts URLs into QR codes for easy sharing." />
                <meta name="keywords" content="QRixy, QR Code, URL, Converter, Sharing" />
                <meta name="author" content="Stephan Hölscher" />

                <meta property="og:title" content="Qrixy - URL to QR Code Converter" />
                <meta
                    property="og:description"
                    content="Convert any URL into a QR code instantly with Qrixy."
                />
                <meta property="og:image" content="%PUBLIC_URL%/og-image.png" />
                <meta property="og:url" content="https://qrixy.me" />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content="Qrixy - URL to QR Code Converter" />
                <meta
                    name="twitter:description"
                    content="Convert any URL into a QR code instantly with Qrixy."
                />
                <meta name="twitter:image" content="%PUBLIC_URL%/twitter-image.png" />

                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            {/* Use MenuBar component */}
            <MenuBar type="home" />
            <Container maxWidth="sm" style={{ textAlign: 'center', color: '#FFFFFF' }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mb: 3,
                    }}
                >                    <Box
                        component="img"
                        src={logo}
                        alt="Qrixy Logo"
                        sx={{ width: 50, height: 'auto', mr: 2 }}
                    />
                    <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#FFFFFF', fontWeight: 'bold' }}>
                        Qrixy - URL to QR Code
                    </Typography>
                </Box>
                <Typography
                    variant="subtitle1"
                    sx={{ color: '#FFFFFF', mb: 3 }}
                >
                    Enter a URL below to generate a QR code that you can share with others.
                </Typography>
                <TextField
                    fullWidth
                    label="Enter URL"
                    variant="outlined"
                    value={url}
                    onChange={handleChange}
                    margin="normal"
                    sx={{
                        mb: 2, input: { color: '#FFFFFF' },
                        label: { color: '#FFFFFF' },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#FFFFFF',
                            },
                            '&:hover fieldset': {
                                borderColor: '#FFFFFF',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#FFFFFF',
                            },
                        },
                        '& .MuiFormHelperText-root': {
                            color: '#FFFFFF',
                        },
                    }}
                />
                {url && !isValidUrl(url) && (
                    <Typography color="error">Please enter a valid URL.</Typography>
                )}
                {url && isValidUrl(url) && (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 5,
                    }} ref={qrRef}>
                        <Box
                            sx={{
                                display: 'inline-block',
                                border: '2px solid #FFFFFF',
                                borderRadius: 2,
                                backgroundColor: '#FFFFFF',
                            }}
                        >
                            <QRCodeCanvas value={url} size={256} marginSize={2} />
                        </Box>
                        <Container maxWidth="sm" sx={{ mt: 2, display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'center' }}>
                            {!isMobile && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleDownload}
                                    sx={{
                                        mt: 2,
                                        color: '#4A148C',
                                        '&:hover': {
                                            backgroundColor: '#F3E5F5',
                                        },
                                    }}
                                    startIcon={<FileDownloadOutlinedIcon />}
                                >
                                    Download
                                </Button>)}
                            {isMobile && (<Button
                                variant="contained"
                                color='primary'
                                onClick={handleShare}
                                sx={{
                                    mt: 2,
                                    backgroundColor: '#FFFFFF',
                                    color: '#4A148C',
                                    '&:hover': {
                                        backgroundColor: '#F3E5F5',
                                    },
                                }}
                                startIcon={<ShareOutlinedIcon />}
                            >
                                Share
                            </Button>)}
                        </Container>
                    </Box>
                )}

                <Box sx={{ mt: 7 }}>
                    <CustomLink
                        to="/faq"
                        text="Got questions? We’ve got answers on our FAQ! 🔍"
                        icon={<EastOutlinedIcon sx={{ fontSize: '1rem' }} />}
                    />
                </Box>
                <Box sx={{ mt: 1 }}>
                    <CustomLink
                        to="/blog"
                        text="Don’t miss out — explore our blog for the latest updates! 🚀"
                        icon={<EastOutlinedIcon sx={{ fontSize: '1rem' }} />}
                    />
                </Box>
            </Container >
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar></>
    );
};

export default QRCodeGenerator;